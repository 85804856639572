import React from "react";
import ContactBar from "../components/ContactBar/ContactBar";
import ContactFormMobile from "../components/ContactForm/ContactFormMobile";
import MailchimpHolder from "../components/Mailchimp/MailchimpHolder";

function Contact () {
    return(
        <div>
        {/* -----------------web & tablet----------------- */}
        <div className="xs-hidden">
            <ContactBar/>
        </div>


        {/* -----------------mobile----------------------- */}
        <div className="lg-hidden md-hidden">
        <div id="contact-bar-holder-mb">
            <div className="top">
            
                <h1>CONTACT</h1>
                <br/>
                <h2>COLLABS & BUSINESS INQUIRIES EMAIL:</h2>
                <p>mustlovemelissa@gmail.com</p>
                <p>Or use the form below ♥</p>
                <br/>
               
            </div>
            <div className="middle">
                <ContactFormMobile/>
                <br></br>
                <br></br>
            </div>
            <div className="bottom">
                <h1>NEWS & UPDATES</h1>
                <MailchimpHolder/>
                </div>

        </div>
        </div>
            
        
        </div>
        
    )
}

export default Contact;