import React from "react";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import PortfolioNav from "./components/PortfolioNav/PortfolioNav";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import DigitalArt from "./pages/DigitalArt";
import FineArt from "./pages/FineArt";
import Fashion from "./pages/Fashion";
import Contact from "./pages/Contact";
import Misc from "./pages/Misc";



function App() {
  return (
    <BrowserRouter>
    <div className="page-wrapper">
    <div className="height">
    <div className="content">
    <PortfolioNav/>

    <Routes>
      <Route path="/" exact element={<Home />}/>
      <Route path="digital-art" element={<DigitalArt />}/>
      <Route path="fine-art" element={<FineArt />}/>
      <Route path="fashion" element={<Fashion />}/>
      <Route path="misc" element={<Misc />}/>
      <Route path="contact" element={<Contact />}/>

    </Routes>
    
      
      </div>
      <Footer/>
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;
