const portfolios = [
    {
        category: "Fine Art",
        href: "/fine-art"
    
    },
    
    {
        category: "Digital Art",
        href: "/digital-art"
    
     },
    
     {
        category: "Fashion",
        href: "/fashion"
    
     },
    
    
     {
        category: "Misc",
        href: "/misc"
     
     },

     {
      category: "Contact",
      href: "/contact"
   
   },


     
    
    ];
    
    export default portfolios;