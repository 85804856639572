const trendImages = [
 
    {
        href: "/images/fashion/trend/denim-work-117.jpg",
        width: 1325,
        height: 2048,

    },
    {
        href: "/images/fashion/trend/denim-work-78.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-71.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-79.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-80.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-106.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-111.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-52.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-72.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-73.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-74.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-75.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-119.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/trend/denim-work-120.jpg",
        width: 2048,
        height: 1325,

    },
  

 
    
    
]

export default trendImages;