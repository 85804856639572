import React from "react";
import PhotoAlbum from "react-photo-album";
import { SRLWrapper } from "simple-react-lightbox";
import fineArtImages from "../data-files/fineArtImages.js"


function FineArt (){

    const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

    const photos = fineArtImages.map((photo) => ({
        src: photo.href,
        width: photo.width,
        height: photo.height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.href,
                width: breakpoint,
                height,
            };
        }),
    }));
// -------------lightbox options -------------------
    const options = {
        buttons: {
            showAutoplayButton: false,
            showCloseButton: true,
            showDownloadButton: false,
            showFullscreenButton: true,
            showNextButton: true,
            showPrevButton: true,
            showThumbnailsButton: false,
            size: '40px'
            }
        }

    return(
        <div>
            <h1>FINE ART</h1>
            <p>Mix of oil paintings, colored pencil, etching</p>
            <SRLWrapper options={options}>
            <PhotoAlbum layout="columns" columns={3} spacing={6} photos={photos} />
            </SRLWrapper>

        </div>
    )
}

export default FineArt;
