const digitalArtImages = [

    
    {
        href: "/images/digital/melissa-santos-mustlovemelissa-mel-santz-art-archie-rivedale-kj-apa-digital-painting.jpg",
        width: 1500,
        height: 1179,

    },

    {
        href: "/images/digital/melissa-santos-mustlovemelissa-mel-santz-art-3.jpg",
        width: 1079,
        height: 1500,

    },
    {
        href: "/images/digital/melissa-santos-mustlovemelissa-mel-santz-art-digital-portrait-painting.jpg",
        width: 1179,
        height: 1500,

    },
    {
        href: "/images/digital/melissa-santos-bubble-gem-machine.jpg",
        width: 792,
        height: 1000,

    },
    
  


    {
        href: "/images/digital/melissa-santos-mustlovemelissa-mel-santz-art-digital-portrait.jpg",
        width: 1179,
        height: 1500,

    },
    {
        href: "/images/digital/melissa-santos-mustlovemelissa-mel-santz-art-4.jpg",
        width: 1096,
        height: 1500,

    },

]

export default digitalArtImages;