import React from "react";
import { NavLink } from "react-router-dom";
import "./PortfolioNav.css"
import MainLogo from "../MainLogo/MainLogo";
import portfolios from "../../data-files/portfolios.js"

function PortfolioNav() {
    return(
    <div>
    <div className="main-logo-holder"><NavLink to="/"><MainLogo/></NavLink></div>
    
    {/* ------------------------WEB---------------------------- */}
        <div id="port-nav-web" className="xs-hidden">
            <div className="menu">
                {portfolios.map(function(port) {
                    return(
                        <NavLink className="nav-text" to={port.href} exact>{port.category}</NavLink>
                    )
                })}
            </div>
        </div>
{/* ----------------------------MOBILE--------------------------- */}
    <div id="port-nav-mobile" className="lg-hidden md-hidden">
        <div className="menu">
                {portfolios.map(function(port) {
                    return(
                        <NavLink className="nav-text" to={port.href} exact>{port.category}</NavLink>
                    )
                })}
            </div>
    </div>

    </div>
    )
}

export default PortfolioNav;