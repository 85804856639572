const fineArtImages = [
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-philippines-waterfall-oil-painting.jpg",
        width: 1500,
        height: 1319,

    },
 
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-cara-delvigne-oil-painting.jpg",
        width: 1024,
        height: 1500,

    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-etching.jpg",
        width: 1124,
        height: 1500,

    },


    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-portrait-oil-painting.jpg",
        width: 1123,
        height: 1500,

    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-oil-painting-lucy-hale.jpg",
        width: 1176,
        height: 1500,

    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-adrian-strugala-oil-painting.jpg",
        width: 1142,
        height: 1500,

    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-verushka-unfinished-oil-painting.jpg",
        width: 1042,
        height: 1500,

    },

    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-cars-oil-painting.jpg",
        width: 1500,
        height: 1135,

    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-29-oil-painting-alberta.jpg",
        width: 1123,
        height: 1500,

    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-hollywood-still-life-oil-painting.jpg",
        width: 1500,
        height: 1200,

    },

    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-colored-pencil-drawing.jpg",
        width: 1158,
        height: 1500,

    },
    {
        href: "/images/fine/melissa-santos-natalie-portman-drawing.jpg",
        width: 708,
        height: 1000,

    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel.santz-art-171.jpg",
        width: 1479,
        height: 1500,

    },
    
]

export default fineArtImages;