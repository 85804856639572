import React from "react";
import "./Footer.css";
import SocialIcons from "../SocialIcons/SocialIcons.jsx"
import mslogo from "../Images/mslogo.jpg";

function Footer() {

    var date = new Date();
    var year = date.getFullYear(); 

    return(
        <div>
        <div id="footer">
            
            <div className="top-bar">
                <img className="ms-logo" src={mslogo} alt="mustlovemelissa logo"></img>
                <SocialIcons/>
            </div>
            <div className="bottom-bar xs-hidden">
            <div className="contents">
            <p>Copyright ©{year}. All rights reserved. Website built by me ♡</p>
            </div>    
            </div>
        {/* --------------   code on mobile device -------------------------- */}
            <div className="bottom-bar md-hidden lg-hidden">
            <div className="contents">
            <p>Copyright ©{year}. All rights reserved.</p>
            <p>Website built by me ♡</p>
            </div>    
            </div>

        </div>
        </div>
    )
}

export default Footer;