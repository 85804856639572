import React, {useState, useEffect} from 'react';
import "./MailchimpHolder.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import InputField from './InputField';

function MailchimpHolder () {

    const mailchimpU = process.env.REACT_APP_MAILCHIMP_U;
    const mailchimpID = process.env.REACT_APP_MAILCHIMP_ID;
    const postUrl = `https://icloud.us10.list-manage.com/subscribe/post?u=${mailchimpU}&id=${mailchimpID}`
    const CustomForm = ({ status, message, onValidated }) => {

        const [email, setEmail] = useState('');
        const [firstName, setFirstName] = useState('');
        const [lastName, setLastName] = useState('');

        const handleSubmit = (e) => {
            e.preventDefault();
            email &&
            firstName &&
            lastName &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
                MERGE1: firstName,
                MERGE2: lastName,
            });
        }

        useEffect(() => {
            if(status === "success") clearFields();
          }, [status])
        
          const clearFields = () => {
            setFirstName('');
            setLastName('');
            setEmail('');
          }

        
    
        return ( 
        <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
        <p className="mc__title">{
            status === "success" ? "Success 🥰" : "Join my mailing list for the latest!"
        }</p>

        {/* ----------error & sending status messages */}

        {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            sending...
          </div>
        )}
        {status === "error" && (
          <div 
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="mc__alert mc__alert--success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        <div className="mc__field-container">
        <div className="input-width">
          <InputField
            label=""
            onChangeHandler={setFirstName}
            type="text"
            value={firstName}
            placeholder="FIRST NAME"
            isRequired
          />
          </div>
          <div className="input-width">
          <InputField
            label=""
            onChangeHandler={setLastName}
            type="text"
            value={lastName}
            placeholder="LAST NAME"
            isRequired
          />
        </div>
        <div className="input-width">
          <InputField
            label=""
            onChangeHandler={setEmail}
            type="email"
            value={email}
            placeholder="EMAIL"
            isRequired
          />
          </div>

        </div>
        <div className="input-width">
        <InputField
          label="subscribe"
          type="submit"
          formValues={[email, firstName, lastName]}
        />
        </div>
      </form>);
    };
   
    return(
        <div>
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status} 
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    )
}

export default MailchimpHolder;