import React from "react"; 
import HomeWeb from "../components/Home/HomeWeb/HomeWeb";
// import HomeTablet from "../components/Home/HomeTablet/HomeTablet";
// import HomeMobile from "../components/Home/HomeMobile/HomeMobile";

function Home() {
    return(
        <div>
        <HomeWeb/>
        {/* <HomeTablet/>
        <HomeMobile/> */}

        </div>
    )
}

export default Home;