const sketchImages = [
 
    {
        href: "/images/fashion/sketches/denim-work-113.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-114.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-107.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-108.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-109.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-110.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-36.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-37.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-38.jpg",
        width: 2048,
        height: 1325,

    },

    {
        href: "/images/fashion/sketches/denim-work-84.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-85.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-86.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-87.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-88.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-89.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-115.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-105.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-40.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-76.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-77.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-104.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-103.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-45.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-81.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-82.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-134.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-112.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/sketches/denim-work-122.jpg",
        width: 2048,
        height: 1325,

    },




  

 
    
    
]

export default sketchImages;