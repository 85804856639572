import React from "react";
import "./SocialIcons.css";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';


const fb = "https://www.facebook.com/mustlovemelissa"
const insta = "https://www.instagram.com/mel.santz/"
const youtube = "https://www.youtube.com/c/MustLoveMelissa"
// const linked = ""

function SocialIcons() {
    return(
        <div className="social-bar">
        <a className="social-link" href={insta}> <InstagramIcon/> </a>
        <a className="social-link" href={youtube}> <YouTubeIcon/> </a>
        <a className="social-link" href={fb}> <FacebookRoundedIcon/> </a> 
            
        </div>
    )
}

export default SocialIcons;