const linesheetImages = [
 
    {
        href: "/images/fashion/linesheet/denim-work-164.jpg",
        width: 1500,
        height: 1000,

    },
    {
        href: "/images/fashion/linesheet/denim-work-93.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-141.jpg",
        width: 1000,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-135.jpg",
        width: 1000,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-95.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-137.jpg",
        width: 1011,
        height: 1335,

    },
    {
        href: "/images/fashion/linesheet/denim-work-149.jpg",
        width: 1000,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-91.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-143.jpg",
        width: 1000,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-90.jpg",
        width: 2048,
        height: 1325,

    },

    {
        href: "/images/fashion/linesheet/denim-work-170.jpg",
        width: 1000,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-145.jpg",
        width: 1500,
        height: 1000,

    },
    {
        href: "/images/fashion/linesheet/denim-work-97.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-155.jpg",
        width: 1179,
        height: 1500,

    },
  
    {
        href: "/images/fashion/linesheet/denim-work-96.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-154.jpg",
        width: 1500,
        height: 1500,

    },
   
 
    {
        href: "/images/fashion/linesheet/denim-work-94.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-151.jpg",
        width: 1000,
        height: 1167,

    },
    {
        href: "/images/fashion/linesheet/denim-work-98.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-99.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-62.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-165.jpg",
        width: 1179,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-142.jpg",
        width: 1000,
        height: 1301,

    },
    {
        href: "/images/fashion/linesheet/denim-work-60.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-148.jpg",
        width: 1000,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-136.jpg",
        width: 1000,
        height: 1500,

    },
    {
        href: "/images/fashion/linesheet/denim-work-57.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-61.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-160.jpg",
        width: 1000,
        height: 939,

    },
    {
        href: "/images/fashion/linesheet/denim-work-161.jpg",
        width: 1000,
        height: 1098,

    },
    {
        href: "/images/fashion/linesheet/denim-work-69.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-163.jpg",
        width: 1500,
        height: 1000,

    },
    {
        href: "/images/fashion/linesheet/denim-work-157.jpg",
        width: 1025,
        height: 1000,

    },
    {
        href: "/images/fashion/linesheet/denim-work-101.jpg",
        width: 2048,
        height: 1325,

    },
    
    {
        href: "/images/fashion/linesheet/denim-work-156.jpg",
        width: 1000,
        height: 1014,

    },
    {
        href: "/images/fashion/linesheet/denim-work-167.jpg",
        width: 1000,
        height: 1357,

    },
    {
        href: "/images/fashion/linesheet/denim-work-53.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-55.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-58.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-66.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-147.jpg",
        width: 1000,
        height: 1500,

    },

   
    {
        href: "/images/fashion/linesheet/denim-work-63.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-65.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-56.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-64.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-67.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-68.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-124.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-42.jpg",
        width: 2048,
        height: 1325,

    },
    {
        href: "/images/fashion/linesheet/denim-work-43.jpg",
        width: 2048,
        height: 1325,

    },
 





  

 
    
    
]

export default linesheetImages;