
import React, { useState } from "react";
import shortBio from "../../data-files/shortBio.js"

function MiniBioText () {

    

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
          setIsReadMore(!isReadMore);
        };
        return (
          <p className="text">
            {isReadMore ? text.slice(0, 500) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
              {isReadMore ? "...read more": " show less"}
            </span>
          </p>
        );
      };

    

    return(

    <div>
    <p>
    <ReadMore>{shortBio}</ReadMore></p></div>

    )
}

export default MiniBioText;