import React from "react";
import PhotoAlbum from "react-photo-album";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import sketchImages from "../../data-files/sketchImages.js";


function Sketch (){

    const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

    const photos = sketchImages.map((photo) => ({
        src: photo.href,
        width: photo.width,
        height: photo.height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.href,
                width: breakpoint,
                height,
            };
        }),
    }));
// -------------lightbox options -------------------
    const options = {
        buttons: {
            showAutoplayButton: false,
            showCloseButton: true,
            showDownloadButton: false,
            showFullscreenButton: true,
            showNextButton: true,
            showPrevButton: true,
            showThumbnailsButton: false,
            size: '40px'
            }
        }

    return(
        <div>
            <h2>CADS & PRESENTATIONS</h2>
            <p>Hand & computer sketches & design concept boards.</p>
            <SimpleReactLightbox>
            <SRLWrapper options={options}>
            <PhotoAlbum layout="rows" columns={3} spacing={6} photos={photos} />
            </SRLWrapper>
            </SimpleReactLightbox>

        </div>
    )
}

export default Sketch;
