import React from "react";
import "./ContactBar.css"
import MailchimpHolder from "../Mailchimp/MailchimpHolder";
import ContactForm from "../ContactForm/ContactForm";
// import ContactFormMobile from "../ContactForm/ContactFormMobile";


function ContactBar () {
    return(
        <div>
        {/* ---------------------------web & tablet------------------- */}
        <div className="xs-hidden">
        <div id="contact-bar-holder-web">
            <div className="left">
                <h1>CONTACT</h1>
                <br/>
                <h2>BUSINESS INQUIRIES:</h2>
                <p>mustlovemelissa@gmail.com</p>
                <br/>
                <h2>NEWS & UPDATES</h2>
                <MailchimpHolder/>
            </div>
            <div className="right">
                <ContactForm/>
            </div>

        </div>
        </div>

        {/* ----------------------mobile--------------------- */}
        <div className="lg-hidden md-hidden">
        <div id="contact-bar-holder-mb">
            
            <div className="bottom">
                <h1>NEWS & UPDATES</h1>
                <MailchimpHolder/>
                </div>

        </div>
        </div>





        </div>
    )
}

export default ContactBar;