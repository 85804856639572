import React from "react"; 
import "./PhotoBar.css"; 
import photoBarImages from "../../data-files/photoBarImages.js"
import { NavLink } from "react-router-dom";


function PhotoBar() {

    const shuffled = photoBarImages.sort(() => 0.5 - Math.random());
    let selected = shuffled.slice(0,3);
    return(
        <div id="photo-bar-web">
            {selected.map(function(img) {
                return(
                    <div className="photo-holder">
                    <NavLink to={img.component}>              
                    <img className="photo-bar-img" src={img.href} alt={img.alt}></img>
                    </NavLink>
                    </div>
                )
            })}

        </div>

    )
}

export default PhotoBar; 
