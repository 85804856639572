import React from "react"; 
import Trend from "../components/FashionSections/Trend";
import Sketch from "../components/FashionSections/Sketch";
import Linesheet from "../components/FashionSections/Linsheet";

function Fashion (){
    return(
        <div>
            <h1>FASHION WORK</h1>
            <div className="section-spacer">
                <Linesheet/>
            </div><hr className="section-spacer"></hr>
            <div className="section-spacer">
                <Sketch/>
            </div><hr className="section-spacer"></hr>
            <div className="section-spacer">
                <Trend/>
            </div>
        </div>
    )
}

export default Fashion;
