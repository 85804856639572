import React from "react";
import "./MiniBio.css"; 
import shortBio from "../../data-files/shortBio.js"
import mustlovemelissa from "../Images/mustlovemelissa.jpg";
import MiniBioText from "./MiniBioText"



function MiniBio() {


    const mobileSrc = "/images/melissa-santos-mustlovemelissa-mel.santz-3.jpg"

    return(
        <div>
        {/* --------------------------web & tablet------------------------- */}
        <div id="minibio-web" className="xs-hidden">
            <div className="container">
                <div className="left">
                <img className="about-photo" src={mustlovemelissa} alt="this is a p"></img>

                </div>
                <div className="right">
                <p>{shortBio}</p>
                <p>Ciao, ciao! 
                <p>Melissa</p></p>
                    
                </div>

            </div>
    

        </div>
        {/* -------------------------mobile------------------------- */}
        <div id="minibio-mobile" className="lg-hidden md-hidden ">
           
        <div className="img-holder">
                <img className="minibio-pic" src={mobileSrc} alt="melissa-santos"/>
            </div>
            <div className="bio section-spacer">
            
           <MiniBioText/>
           <p>Ciao, ciao! 
                <p>Melissa</p></p>
           
            </div>
            
        </div>

        </div>

    )
}

export default MiniBio; 
