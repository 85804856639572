import React from "react";
import siglogo from "../Images/siglogo.png";
import "./MainLogo.css";

function MainLogo() {
    return(
    <div id="main-logo-holder">
        
        <img className="melissa-logo " src={siglogo} alt="melissa santos signature logo"></img>
           
    </div>
    )
}

export default MainLogo;