const miscImages = [
 
    {
        href: "/images/misc/melissa-santos-mustlovemelissa-mel-santz-art-24.jpg",
        width: 1500,
        height: 1000,

    },  
    {
        href: "/images/misc/melissa-santos-mustlovemelissa-mel-santz-art-7.jpg",
        width: 1373,
        height: 1500,

    },
   
       {
        href: "/images/misc/melissa-santos-mustlovemelissa-mel-santz-art-22.jpg",
        width: 1500,
        height: 844,

    },  
    {
        href: "/images/misc/melissa-santos-mustlovemelissa-mel-santz-art-8.jpg",
        width: 1262,
        height: 1500,

    },
  

    
]

export default miscImages;