import React from "react";
import PhotoBar from "../../PhotoBar/PhotoBar.jsx"
import MiniBio from "../../MiniBio/MiniBio"
import ContactBar from "../../ContactBar/ContactBar.jsx";

function HomeWeb() {
    return(
        <div>
            <PhotoBar/>
            <hr className="xs-hidden"/>
            <MiniBio/>
            <hr/>
            <ContactBar/>
        </div>
    )
}

export default HomeWeb;