const photoBarImages = [
    // {
    //     href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-20.jpg",
    //     alt: "oil painting",
    //     category: "fine art",
    //     component: "/fine-art"
    // },
    {
        href: "/images/digital/melissa-santos-mustlovemelissa-mel-santz-art-18.jpg",
        alt: "oil painting",
        category: "digital",
        component: "/digital-art"
    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel.santz-art-173.jpg",
        alt: "oil painting",
        category: "fine art",
        component: "/fine-art"
    },
    {
        href: "/images/fine/melissa-santos-mustlovemelissa-mel-santz-art-lucy-hale-oil-painting.jpg",
        alt: "oil painting",
        category: "fine art",
        component: "/fine-art"
    },
   
    // {
    //     href: "/images/jace.jpg",
    //     alt: "oil painting",
    //     category: "fine art",
    //     component: "/#branding"
    // },
    // {
    //     href: "/images/melissa-santos-digital-painting-2.jpg",
    //     alt: "oil painting",
    //     category: "fine art",
    //     component: "/#branding"
    // },
    // {
    //     href: "/images/melissa-santos-party-animal.jpg",
    //     alt: "oil painting",
    //     category: "fine art",
    //     component: "/#branding"
    // },
];

export default photoBarImages;